<template>
    <div class="pressure-record-edit-parameter-bpap-st">
        <div class="pressure-record-edit-parameter-bpap-st-top">
            <div class="pressure-record-edit-parameter-bpap-st-column" style="margin-right: 25px;">
                <record-selector title="起始压力" v-model="startPressure" :dataArray="startPressureArray"></record-selector>
                <record-selector title="吸气压力" v-model="breathInPressure"
                    :dataArray="breathInPressureArray"></record-selector>
                <record-selector title="升压速度" v-model="upSpeed" :dataArray="upSpeedArray"></record-selector>
                <record-selector title="呼吸频率" v-model="breathFrequency" :dataArray="breathFrequencyArray"></record-selector>
            </div>
            <div class="pressure-record-edit-parameter-bpap-st-column">
                <record-selector title="最小吸气时间" v-model="minBreathInTime"
                    :dataArray="minBreathInTimeArray"></record-selector>
                <record-selector title="呼气压力" v-model="breathOutPressure"
                    :dataArray="breathOutPressureArray"></record-selector>
                <record-selector title="降压速度" v-model="downSpeed" :dataArray="downSpeedArray"></record-selector>
                <record-selector title="吸呼比" v-model="breathRatio" :dataArray="breathRatioArray"></record-selector>
            </div>
        </div>
        <common-bottom ref="bottomRef" :cureMode="cureMode" :initParamModel="initParamModel"></common-bottom>
    </div>
</template>

<script>
import RecordSelector from "./component/pop-view-holography-case-edit-parameter-selector.vue";
import RecordSwitch from "@c/record-frame/common/record-switch.vue";
import RecordRadio from "@c/record-frame/common/record-radio.vue";
import CommonBottom from "./bottom.vue";

import { pressureArray, commonArray, minBreathInTimeArray, breathFrequencyArray, breathRatioArray } from "@js/parameter-option-data-source.js";
export default {

    components: {
        RecordSelector,
        RecordSwitch,
        RecordRadio,
        CommonBottom
    },

    props: {
        initParamModel: Object
    },

    data() {
        return {
            cureMode: CURE_MODE.BPAPST,
            paramModel: undefined,
            //选中项
            startPressure: undefined,
            minBreathInTime: undefined,
            breathInPressure: undefined,
            breathOutPressure: undefined,
            upSpeed: undefined,
            downSpeed: undefined,
            breathFrequency: undefined,
            breathRatio: undefined,
            delayPressure: undefined,
            wetLevel: undefined,
            smartStart: undefined,
            smartStop: undefined,
            leakRemindTime: undefined,
            maskType: undefined,
            maskCategory: undefined,
            tubeType: undefined,
            //选项数组
            upSpeedArray: commonArray,
            downSpeedArray: commonArray,
            breathFrequencyArray: breathFrequencyArray(),
            breathRatioArray: breathRatioArray(),
            //ref
            bottomRef: undefined
        }
    },

    computed: {
        //选项数组
        startPressureArray() {
            return pressureArray(4, this.breathOutPressure)
        },

        minBreathInTimeArray() {
            return minBreathInTimeArray(this.breathFrequency, this.breathRatio)
        },

        breathInPressureArray() {
            return pressureArray(this.breathOutPressure, 30)
        },

        breathOutPressureArray() {
            return pressureArray(this.startPressure, this.breathInPressure)
        },

        jsonStr() {
            return JSON.stringify({
                cureMode: this.cureMode,
                startPressure: this.startPressure * 10,
                stTimin: this.minBreathInTime,
                stInP: this.breathInPressure * 10,
                stExP: this.breathOutPressure * 10,
                boostslope: this.upSpeed,
                buckslope: this.downSpeed,
                breathRate: this.breathFrequency,
                breathRatio: this.breathRatio,
                cureDelay: this.$refs.bottomRef.delayPressure,
                humidifyLevel: this.$refs.bottomRef.wetLevel,
                intelligentStart: this.$refs.bottomRef.smartStart,
                intelligentStop: this.$refs.bottomRef.smartStop,
                leakAlarm: this.$refs.bottomRef.leakRemindTime,
                maskTypeId: this.$refs.bottomRef.maskType,
                mask: this.$refs.bottomRef.maskCategory,
                pipe: this.$refs.bottomRef.tubeType,
                heattubeTem: this.$refs.bottomRef.tubeType == 0 ? 0 : this.$refs.bottomRef.heatTubeTemperature
            })
        }
    },

    async created() {
        this.paramModel = { ...this.initParamModel }
        if (this.paramModel.cureMode == this.cureMode) {
            this.startPressure = parseFloat(this.paramModel.startPressure / 10)
            this.minBreathInTime = parseInt(this.paramModel.stTimin)
            this.breathInPressure = parseFloat(this.paramModel.stInP / 10)
            this.breathOutPressure = parseFloat(this.paramModel.stExP / 10)
            this.upSpeed = parseInt(this.paramModel.boostslope)
            this.downSpeed = parseInt(this.paramModel.buckslope)
            this.breathFrequency = parseInt(this.paramModel.breathRate)
            this.breathRatio = parseInt(this.paramModel.breathRatio)
        } else {
            this.paramModel.cureMode = this.cureMode
            this.startPressure = 4
            this.minBreathInTime = 5
            this.breathInPressure = 8
            this.breathOutPressure = 6
            this.upSpeed = 3
            this.downSpeed = 3
            this.breathFrequency = 10
            this.breathRatio = 1
        }
    },

    mounted() {
        this.bottomRef = this.$refs.bottomRef
    }
}
</script>

<style lang="scss" scoped>
.pressure-record-edit-parameter-bpap-st {
    &-top {
        display: flex;
    }

    &-bottom {
        display: flex;
    }

    &-column {
        width: 50%;
    }
}
</style>